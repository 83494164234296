import React from 'react';
import { Routes, Route, Link } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Home from "./pages/Home";
import Lookup from "./pages/Lookup";
import Guide from "./pages/Guide";
import Process from "./pages/Process";
import {zii_src, zii_t} from "./utils/util";
import {Col, Row} from "react-bootstrap";
import TradeTrainApply from "./pages/TradeTrainApply";

function App() {
  return (
    <>
      <div className="">
        <Routes>
          <Route path="/" element={<Guide />} />
          <Route path="lookup" element={<Lookup />} />
          <Route path="guide" element={<Home />} />
          <Route path="process" element={<Process />} />
          <Route path="about" element={<About />} />
          <Route path="trade-train-apply" element={<TradeTrainApply />} />
        </Routes>
      </div>
    </>
  );
}

function About() {
  return (
    <>
      <main>
        <h2>Who are we?</h2>
        <p>
          That feels like an existential question, don't you
          think?
        </p>
      </main>
      <nav>
        <Link to="/">Home</Link>
      </nav>
    </>
  );
}

export default App;
