import React from "react";

export default function () {
  return (
    <>
      <div className={`mt-0 div-btn ${window.location.pathname === '/guide' ? "div-btn-with-bubble" : ""} text-white text-center small`}
           onClick={() => {window.location.href = '/guide'}}
      >申报流程</div>
      <div className={`mt-3 div-btn ${window.location.pathname === '/lookup' ? "div-btn-with-bubble" : ""} text-white text-center small`}
           onClick={() => {window.location.href = '/lookup'}}
      >评价程序</div>
      <div className={`mt-3 div-btn ${window.location.pathname === '/' ? "div-btn-with-bubble" : ""} text-white text-center small`}
           onClick={() => {window.location.href = '/'}}
      >文件介绍</div>
      <div className={`mt-3 div-btn ${window.location.pathname === '/process' ? "div-btn-with-bubble" : ""} text-white text-center small`}
           onClick={() => {window.location.href = '/process'}}
      >申报进度</div>
      <div className={`mt-3 div-btn text-white text-center small`}
           onClick={() => {
             // @ts-ignore
             window.open("https://work.weixin.qq.com/kfid/kfce6325b6574e7c42d");
           }}
      ><i className="bi bi-headset position-relative" style={{top: "1px"}}></i> 咨询客服</div>
    </>
  )
}
