import {Button, Spinner, Col, Container, Form, Modal, Row} from "react-bootstrap";

import React, {useEffect, useState} from "react";
import axios from "axios";
import Cookies from 'js-cookie';
import LeftNav from "../components/LeftNav";

const serverApiHost = 'https://api.lowcarboncity.com.cn';

axios.interceptors.request.use((config) => {
  if (config.headers == null) {
    config.headers = {};
  }

  // 必需，这样可以显示后端打印输出
  config.headers["X-Requested-With"] = "XMLHttpRequest";

  const _cookieId: any = Cookies.get('cea_id');

  if (_cookieId) {
    config.headers["X-Register-Ref"] = _cookieId;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

export default function () {
  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsisLoading] = useState(false);
  const [db, setDb] = useState({} as any);

  useEffect(() => {
    if (!isMounted) {
      if (!Cookies.get('cea_id')) {
        const loginUrl = 'https://www.lowcarboncity.com.cn/Attestation/user/certificate_LoginQ.html';
        window.location.href = `${loginUrl}?afterLogin=${encodeURIComponent(window.location.href)}`;
        return;
      }

      setIsMounted(true);
    }
  }, [isMounted]);

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    setIsisLoading(true);
    axios.get(`${serverApiHost}/openapi/apply-low-carbon-supplier/read`)
      .then((resp) => {
        const {data, isOk} = resp.data as any;

        if (!isOk) {
          alert(data.err);
          return;
        }

        setDb(data);
      })
      .catch(() => {
        alert('网络繁忙');
      })
      .finally(() => {
        setIsisLoading(false);
      });
  }, [isMounted]);

  return(
    <>
      <div className="d-flex align-items-center min-vh-100"
           style={{
             backgroundImage: "url(https://fs-1300425912.cos.ap-shanghai.myqcloud.com/localupload/20221018/bg.png)",
             backgroundRepeat: "no-repeat",
             backgroundPosition: "center center",
             backgroundSize: "cover",
           }}>
        <Container className="py-5">
          <h4 className="text-center text-white">低碳产品供应商申报</h4>
          <div className={`mt-4 mx-auto w-75`} style={{
            transitionProperty: "width",
            transitionDuration: "0.7s",
          }}>
            <div className="p-5" style={{backgroundColor: "rgba(255,255,255,0.5)"}}>
              <Row>
                <Col className={"col-3"}>
                  <LeftNav/>
                </Col>
                <Col className={"col-9"}>
                  <div className="bg-white px-4 pb-5 pt-2 animate__animated animate__fadeIn">
                    <p className="my-0 text-end">
                      <a href="https://www.lowcarboncity.com.cn/" target="_blank" className="link-dark small d-inline-block ms-3" style={{textUnderlineOffset: "2px"}}>
                        低碳城首页 <i className="bi bi-box-arrow-up-right"></i>
                      </a>
                      <a href="https://www.lowcarboncity.com.cn/Attestation/user/homeIndex.html" target="_blank" className="link-dark small d-inline-block ms-3" style={{textUnderlineOffset: "2px"}}>
                        中国碳标签评价服务信息平台 <i className="bi bi-box-arrow-up-right"></i>
                      </a>
                    </p>
                    <h6 className="fw-normal text-info">温馨提示</h6>
                    <div className="my-3">
                      <p>当前申报状态：<span className="text-info">{db.staff_check}</span></p>
                      <div className="progress" style={{height: "2em"}}>
                        <div className={`progress-bar ${db.id ? "bg-info" : 'bg-light text-dark'}`} role="progressbar" style={{width: "33%"}}>1.信息提交</div>
                        <div className={`progress-bar ${db.staff_check === "审核通过" ? "bg-info" : 'bg-light text-dark'}`} role="progressbar" style={{width: "33%"}}>2.信息核查</div>
                        <div className={`progress-bar ${db.staff_check === "审核通过" ? "bg-info" : 'bg-light text-dark'}`} role="progressbar" style={{width: "34%"}}>3.申报成功</div>
                      </div>
                    </div>
                    <p className="text-muted small">* 推荐使用Chrome、火狐浏览器、360浏览器、搜狗浏览器、QQ浏览器，并关闭兼容模式。</p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
