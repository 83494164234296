import {Button, Spinner, Col, Container, Form, Modal, Row} from "react-bootstrap";

import React, {useEffect, useState} from "react";
import axios from "axios";
import Cookies from 'js-cookie';
import LeftNav from "../components/LeftNav";

const serverApiHost = 'https://api.lowcarboncity.com.cn';

axios.interceptors.request.use((config) => {
  if (config.headers == null) {
    config.headers = {};
  }

  // 必需，这样可以显示后端打印输出
  config.headers["X-Requested-With"] = "XMLHttpRequest";

  const _cookieId: any = Cookies.get('cea_id');

  if (_cookieId) {
    config.headers["X-Register-Ref"] = _cookieId;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

export default function () {
  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsisLoading] = useState(false);
  const [step1Values, setStep1Values] = useState({} as any);
  const [step, setStep] = useState(0);

  const [easyDialog, setEasyDialog] = useState({} as any);

  const setStep1ValuesByInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('input', e.currentTarget.name, e.currentTarget.value);
    setStep1Values({...step1Values, [e.currentTarget.name]: e.currentTarget.value});
  }

  const setStep1ValuesBySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    console.log('select:', e.currentTarget.name, e.currentTarget.value);
    setStep1Values({...step1Values, [e.currentTarget.name]: e.currentTarget.value});
  }

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
  }, [isMounted]);

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    const urlSearchParams = new URLSearchParams(window.location.search);
    const q = Object.fromEntries(urlSearchParams.entries());

    if (q.step && /^\d+$/.test(`${q.step}`)) {
      setStep((q.step as any) * 1);
    }
  }, [isMounted]);

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    if (step > 0) {
      if (!Cookies.get('cea_id')) {
        const loginUrl = 'https://www.lowcarboncity.com.cn/Attestation/user/certificate_LoginQ.html';
        window.location.href = `${loginUrl}?afterLogin=${encodeURIComponent(window.location.href)}`;
        return;
      }
    }
  }, [isMounted, step]);

  return(
    <>
      <div className="d-flex align-items-center min-vh-100"
           style={{
             backgroundImage: "url(https://fs-1300425912.cos.ap-shanghai.myqcloud.com/localupload/20221018/bg.png)",
             backgroundRepeat: "no-repeat",
             backgroundPosition: "center center",
             backgroundSize: "cover",
           }}>
        <Container className="py-5">
          <h4 className="text-center text-white">低碳产品供应商申报</h4>
          <div className={`mt-4 mx-auto ${step > 0 ? "w-100" : "w-75"}`} style={{
            transitionProperty: "width",
            transitionDuration: "0.7s",
          }}>
            <div className="p-5" style={{backgroundColor: "rgba(255,255,255,0.5)"}}>
              <Row>
                <Col className={step > 0 ? "col-2" : "col-3"}>
                  <LeftNav/>
                </Col>
                <Col className={step > 0 ? "col-10" : "col-9"}>
                  {
                    step === 0 ? (
                      <div key="step0" className="bg-white px-4 pb-5 pt-2 animate__animated animate__fadeIn">
                        <p className="my-0 text-end">
                          <a href="https://www.lowcarboncity.com.cn/" target="_blank" className="link-dark small d-inline-block ms-3" style={{textUnderlineOffset: "2px"}}>
                            低碳城首页 <i className="bi bi-box-arrow-up-right"></i>
                          </a>
                          <a href="https://www.lowcarboncity.com.cn/Attestation/user/homeIndex.html" target="_blank" className="link-dark small d-inline-block ms-3" style={{textUnderlineOffset: "2px"}}>
                            中国碳标签评价服务信息平台 <i className="bi bi-box-arrow-up-right"></i>
                          </a>
                        </p>
                        <h6 className="fw-normal text-info">温馨提示</h6>
                        <p className="my-3">请仔细阅读相关文件和申报要求</p>
                        <p className="text-danger small">温馨提示：申报请使用PC端浏览器进行申报</p>
                        <p className="text-muted small">* 推荐使用Chrome、火狐浏览器、360浏览器、搜狗浏览器、QQ浏览器，并关闭兼容模式。</p>
                        <hr className="mt-5"/>
                        <Row>
                          <Col className="text-start">
                            <Button variant="info"
                                    size="sm"
                                    className="px-5 py-2 border-0 text-white"
                                    onClick={() => {window.location.href = '/guide?step=1'}}
                            >马上申请</Button>
                          </Col>
                          <Col className="text-end">
                            <Button className="px-5 py-2"
                                    size="sm"
                                    variant="outline-dark"
                                    onClick={() => setEasyDialog({
                                      ...easyDialog,
                                      isOpen: true,
                                      title: '申报资质',
                                      content: `
<p>1. 申报单位应具有独立法人资格，协会会员单位优先。</p>
<p>2. 申报单位近三年内未发生过较大及以上质量、环保、安全生产责任事故，以及受政府相关部门处罚的违法违规及重大失信行为。</p>
<p>3. 评价工作采取企业自愿申请或行业协会、有关单位推荐的办法。</p>
<p>4. 低碳产品供应商评价推荐单位应为碳标签授权评价机构、全国性协会商会、省级协会商会。</p>
                                      `,
                                    })}
                            >申报资质</Button>
                          </Col>
                        </Row>
                      </div>
                    ) : ""
                  }
                  {
                    step === 1 ? (
                      <div key="step1" className="bg-white px-4 py-5">
                        <Form className="animate__animated animate__fadeIn" onSubmit={(e) => {
                          e.preventDefault();

                          const formData = new FormData(e.target as any);
                          const formProps = Object.fromEntries(formData);

                          setStep1Values(formProps);

                          setIsisLoading(true);
                          axios.post(`${serverApiHost}/openapi/apply-low-carbon-supplier/inbox`, e.target, {
                            headers: {
                              'Content-Type': 'application/json',
                            }
                          })
                            .then((resp) => {
                              const {data, isOk} = resp.data as any;

                              if (!isOk) {
                                alert(data.err);
                                return;
                              }

                              setStep(step + 1);
                            })
                            .catch(() => {
                              alert('网络繁忙');
                            })
                            .finally(() => {
                              setIsisLoading(false);
                            });
                        }}>
                          <fieldset disabled={false}>
                          <Row className="">
                            <Form.Group as={Col} className="">
                              <Form.Label>单位名称</Form.Label>
                              <Form.Control required name="name" onChange={setStep1ValuesByInput} value={step1Values.name} type="text" placeholder=""/>
                            </Form.Group>
                            <Form.Group as={Col} className="">
                              <Form.Label>统一社会信用代码</Form.Label>
                              <Form.Control required name="uscc" onChange={setStep1ValuesByInput} value={step1Values.uscc} type="text" placeholder=""/>
                            </Form.Group>
                          </Row>
                          <Row className="mt-3">
                            <Form.Group as={Col} className="col-8">
                              <Form.Label>地址</Form.Label>
                              <Form.Control required name="address" onChange={setStep1ValuesByInput} value={step1Values.address} type="text" placeholder=""/>
                            </Form.Group>
                            <Form.Group as={Col} className="col-4">
                              <Form.Label>邮编</Form.Label>
                              <Form.Control required name="postcode" onChange={setStep1ValuesByInput} value={step1Values.postcode} type="text" placeholder=""/>
                            </Form.Group>
                          </Row>
                          <Row className="mt-3">
                            <Form.Group as={Col} className="">
                              <Form.Label>单位电话</Form.Label>
                              <Form.Control required name="tel" onChange={setStep1ValuesByInput} value={step1Values.tel} type="text" placeholder=""/>
                            </Form.Group>
                            <Form.Group as={Col} className="">
                              <Form.Label>单位传真</Form.Label>
                              <Form.Control required name="fax" onChange={setStep1ValuesByInput} value={step1Values.fax} type="text" placeholder=""/>
                            </Form.Group>
                          </Row>
                          <Row className="mt-3">
                            <Form.Group as={Col} className="">
                              <Form.Label>成立时间</Form.Label>
                              <Form.Control required name="founded_date" onChange={setStep1ValuesByInput} value={step1Values.founded_date} type="date" pattern="\d{4}-\d{2}-\d{2}" placeholder=""/>
                            </Form.Group>
                            <Form.Group as={Col} className="">
                              <Form.Label>网址</Form.Label>
                              <Form.Control required name="website" onChange={setStep1ValuesByInput} value={step1Values.website} type="text" placeholder=""/>
                            </Form.Group>
                          </Row>
                          <Row className="mt-3">
                            <Form.Group as={Col} className="">
                              <Form.Label>注册资金（万元）</Form.Label>
                              <Form.Control required name="founded_money" onChange={setStep1ValuesByInput} value={step1Values.founded_money} type="tel" placeholder=""/>
                            </Form.Group>
                            <Form.Group as={Col} className="">
                              <Form.Label>总资产（万元）</Form.Label>
                              <Form.Control required name="total_money" onChange={setStep1ValuesByInput} value={step1Values.total_money} type="tel" placeholder=""/>
                            </Form.Group>
                          </Row>
                          <Row className="mt-3">
                            <Form.Group as={Col} className="">
                              <Form.Label>法人代表</Form.Label>
                              <Form.Control required name="lr" onChange={setStep1ValuesByInput} value={step1Values.lr} type="text" placeholder=""/>
                            </Form.Group>
                            <Form.Group as={Col} className="">
                              <Form.Label>电话/手机</Form.Label>
                              <Form.Control required name="lr_tel" onChange={setStep1ValuesByInput} value={step1Values.lr_tel} type="tel" placeholder=""/>
                            </Form.Group>
                            <Form.Group as={Col} className="">
                              <Form.Label>Email</Form.Label>
                              <Form.Control required name="lr_email" onChange={setStep1ValuesByInput} value={step1Values.lr_email} type="email" placeholder=""/>
                            </Form.Group>
                          </Row>
                          <Row className="mt-3">
                            <Form.Group as={Col} className="">
                              <Form.Label>总经理</Form.Label>
                              <Form.Control required name="chief" onChange={setStep1ValuesByInput} value={step1Values.chief} type="text" placeholder=""/>
                            </Form.Group>
                            <Form.Group as={Col} className="">
                              <Form.Label>电话/手机</Form.Label>
                              <Form.Control required name="chief_tel" onChange={setStep1ValuesByInput} value={step1Values.chief_tel} type="tel" placeholder=""/>
                            </Form.Group>
                            <Form.Group as={Col} className="">
                              <Form.Label>Email</Form.Label>
                              <Form.Control required name="chief_email" onChange={setStep1ValuesByInput} value={step1Values.chief_email} type="email" placeholder=""/>
                            </Form.Group>
                          </Row>
                          <Row className="mt-3">
                            <Form.Group as={Col} className="">
                              <Form.Label>联系人</Form.Label>
                              <Form.Control required name="contact" onChange={setStep1ValuesByInput} value={step1Values.contact} type="text" placeholder=""/>
                            </Form.Group>
                            <Form.Group as={Col} className="">
                              <Form.Label>电话/手机</Form.Label>
                              <Form.Control required name="contact_tel" onChange={setStep1ValuesByInput} value={step1Values.contact_tel} type="tel" placeholder=""/>
                            </Form.Group>
                            <Form.Group as={Col} className="">
                              <Form.Label>Email</Form.Label>
                              <Form.Control required name="contact_email" onChange={setStep1ValuesByInput} value={step1Values.contact_email} type="email" placeholder=""/>
                            </Form.Group>
                          </Row>
                          <Row className="mt-3">
                            <Form.Group as={Col} className="">
                              <Form.Label>在职人员人数</Form.Label>
                              <Form.Control required name="employee_total" onChange={setStep1ValuesByInput} value={step1Values.employee_total} type="tel" placeholder=""/>
                            </Form.Group>
                            <Form.Group as={Col} className="">
                              <Form.Label>技术人员人数</Form.Label>
                              <Form.Control required name="employee_tech" onChange={setStep1ValuesByInput} value={step1Values.employee_tech} type="tel" placeholder=""/>
                            </Form.Group>
                          </Row>
                          <Row className="mt-3">
                            <Form.Group as={Col} className="">
                              <Form.Label>质量管理体系</Form.Label>
                              <Form.Select required name="quality_management_system" onChange={setStep1ValuesBySelect} value={step1Values.quality_management_system}>
                                <option value="">请选择</option>
                                <option value="有">有</option>
                                <option value="无">无</option>
                              </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} className="">
                              <Form.Label>专利/软件著作权数量</Form.Label>
                              <Form.Control required name="copyright_total" onChange={setStep1ValuesByInput} value={step1Values.copyright_total} type="tel" placeholder=""/>
                            </Form.Group>
                          </Row>
                          <Form.Group className="mt-3">
                            <Form.Label>低碳专业人员数量（参加低碳领域相关培训的人员数量）</Form.Label>
                            <Form.Control required name="employee_carbon" onChange={setStep1ValuesByInput} value={step1Values.employee_carbon} type="tel" placeholder=""/>
                          </Form.Group>
                          </fieldset>

                          <div className="mt-5 text-center">
                            <Button variant="light"
                                    className="d-inline-block mx-2 px-5 py-2 border-0"
                                    size="sm"
                                    disabled={isLoading}
                                    onClick={() => {window.location.href = '/'}}
                            >返回</Button>
                            <Button variant="info"
                                    className="d-inline-block mx-2 px-5 py-2 border-0 text-white"
                                    size="sm"
                                    type="submit"
                                    disabled={isLoading}
                            >{isLoading ? <Spinner size="sm" animation="border" /> : "下一步"}</Button>
                          </div>
                        </Form>
                      </div>
                    ) : ""
                  }
                  {
                    step === 2 ? (
                      <div key="step2" className="bg-white px-4 py-5">
                        <div className="animate__animated animate__fadeIn">
                          <p>
                            <a href="/attachments/低碳产品供应商评价真实性承诺书.docx"
                               target="_blank"
                               className="link-info"
                            >下载附件：低碳产品供应商评价真实性承诺书.docx</a>
                          </p>
                          <p className="text-danger small">填写低碳产品供应商评价真实性承诺书，盖章后点击下一步进行上传</p>
                        </div>
                        <div className="mt-5 text-center">
                          <Button variant="light"
                                  className="d-inline-block mx-2 px-5 py-2 border-0"
                                  size="sm"
                                  disabled={isLoading}
                                  onClick={() => setStep(step - 1)}
                          >上一步</Button>
                          <Button variant="info"
                                  className="d-inline-block mx-2 px-5 py-2 border-0 text-white"
                                  size="sm"
                                  disabled={isLoading}
                                  onClick={() => {setStep(step + 1)}}
                          >{isLoading ? <Spinner size="sm" animation="border" /> : "下一步"}</Button>
                        </div>
                      </div>
                    ) : ""
                  }
                  {
                    step === 3 ? (
                        <div key="step3" className="bg-white px-4 py-5">
                          <Form className="animate__animated animate__fadeIn" onSubmit={(e) => {
                            e.preventDefault();

                            setIsisLoading(true);
                            axios.post(
                              `${serverApiHost}/openapi/apply-low-carbon-supplier/inbox`,
                              e.target,
                              {
                                headers: {
                                  'Content-Type': 'multipart/form-data',
                                }
                              }
                            )
                              .then((resp) => {
                                const {data, isOk} = resp.data as any;

                                if (!isOk) {
                                  alert(data.err);
                                  return;
                                }

                                setStep(step + 1);
                              })
                              .catch(() => {
                                alert('网络繁忙');
                              })
                              .finally(() => {
                                setIsisLoading(false);
                              });
                          }}>
                            <Form.Group className="">
                              <Form.Label>请上传附件：低碳产品供应商评价真实性承诺书</Form.Label>
                              <Form.Control required name="cheng_nuo_shu" type="file" accept=".zip,.rar,.pdf,.docx,.doc,.pptx,.ppt,.xlsx,.xls,image/*" />
                            </Form.Group>
                            <Form.Group className="mt-4">
                              <Form.Label>请上传营业执照</Form.Label>
                              <Form.Control required name="ying_ye_zhi_zhao" type="file" accept=".zip,.rar,.pdf,.docx,.doc,.pptx,.ppt,.xlsx,.xls,image/*" />
                            </Form.Group>
                            <Form.Group className="mt-4">
                              <Form.Label>请上传前一年度财务报表复印件</Form.Label>
                              <Form.Control required name="cai_wu_bao_biao" type="file" accept=".zip,.rar,.pdf,.docx,.doc,.pptx,.ppt,.xlsx,.xls,image/*" />
                            </Form.Group>
                            <Form.Group className="mt-4">
                              <Form.Label>请上传会员证明材料</Form.Label>
                              <Form.Control required name="hui_yuan_zheng_shu" type="file" accept=".zip,.rar,.pdf,.docx,.doc,.pptx,.ppt,.xlsx,.xls,image/*" />
                            </Form.Group>

                            <Form.Group className="mt-4">
                              <Form.Label>请上传低碳产品供应商评价报告</Form.Label>
                              <Form.Control required name="dtcpgyspjbg" type="file" accept=".zip,.rar,.pdf,.docx,.doc,.pptx,.ppt,.xlsx,.xls,image/*" />
                            </Form.Group>
                            <p className="my-1 text-danger small">
                              （如无报告，请提交与
                              <span className="text-info">文件介绍：附件2</span>
                              有关材料或说明）
                            </p>
                            <p className="text-danger small">（附件2的三类指标，基本指标提供资料完备度需为100%，必选指标提供资料完备度需不少于80%，可选指标提供资料完备度需不少于20%。）</p>

                            <div className="mt-5 text-center">
                              <Button variant="light"
                                      className="d-inline-block mx-2 px-5 py-2 border-0"
                                      size="sm"
                                      disabled={isLoading}
                                      onClick={() => setStep(step - 1)}
                              >上一步</Button>
                              <Button variant="info"
                                      className="d-inline-block mx-2 px-5 py-2 border-0 text-white"
                                      size="sm"
                                      type="submit"
                                      disabled={isLoading}
                              >{isLoading ? <Spinner size="sm" animation="border" /> : "提交审核"}</Button>
                            </div>
                          </Form>
                        </div>
                    ) : ""
                  }
                  {
                    step === 4 ? (
                        <div key="step4" className="bg-white px-4 py-5">
                          <div className="animate__animated animate__fadeIn">
                            <p className="text-center text-info"><i className="bi bi-check-circle" style={{fontSize: "60px"}}></i></p>
                            <p className="text-center text-info fs-5">提交成功，请等待工作人员审核</p>
                          </div>
                        </div>
                    ) : ""
                  }
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>

      <Modal className="rounded-0 border-0"
        show={easyDialog.isOpen}
        // backdrop="static"
        // keyboard={false}
        centered
             onHide={() => setEasyDialog({...easyDialog, isOpen: false})}
      >
        <Modal.Header closeButton className="pe-4">
          <h6 className="my-0">{easyDialog.title}</h6>
        </Modal.Header>
        <Modal.Body className="px-5 pb-5" dangerouslySetInnerHTML={{__html: easyDialog.content}}/>
      </Modal>
    </>
  );
};
