import React, {useEffect, useState} from "react";
import axios from "axios";
import Cookies from 'js-cookie';
import {embed} from 'pdfobject';
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";

const serverApiHost = 'https://api.lowcarboncity.com.cn';

axios.interceptors.request.use((config) => {
  if (config.headers == null) {
    config.headers = {};
  }

  // 必需，这样可以显示后端打印输出
  config.headers["X-Requested-With"] = "XMLHttpRequest";

  const _cookieId: any = Cookies.get('cea_id');

  if (_cookieId) {
    config.headers["X-Register-Ref"] = _cookieId;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

export default function () {
  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsisLoading] = useState(false);

  const [state, setState] = useState({
    company_types: [],
  });

  const onCheckboxToggle = ({target}: any) => {
    setState((state: any) => ({...state, [target.name]: !state[target.name]}));

    console.log('state:', state);
  }

  useEffect(() => {
    if (!isMounted) {
      document.title = '《碳关税·碳金融·绿色贸易》专项人才培训报名';
      setIsMounted(true);
    }
  }, [isMounted]);

  useEffect(() => {
    if (!isMounted) {
      return;
    }

  }, [isMounted]);

  return (
    <>
      <div className="bg-light">
        <div className="container ">
          <div className="w-75 mx-auto px-4 py-5">
            <h3 className="text-center">《碳关税·碳金融·绿色贸易》专项人才培训报名</h3>
            <Form id="form001"
                  className=""
                  onChange={(e) => {
                    const formData = new FormData(document.getElementById('form001') as any);
                    const formProps = Object.fromEntries(formData);
                    console.log("formProps:", formProps);
                  }}
                  onSubmit={(e) => {
                    e.preventDefault();

                    const formData = new FormData(e.target as any);
                    const formProps = Object.fromEntries(formData);

                    setIsisLoading(true);
                    axios.post(`${serverApiHost}/webapp/apply-low-carbon-supplier/inbox`, e.target, {
                      headers: {'Content-Type': 'application/json'}
                    })
                      .then((resp) => {
                        const {data, isOk} = resp.data as any;

                        if (!isOk) {
                          alert(data.err);
                          return;
                        }

                        alert('提交成功');
                      })
                      .catch(() => {
                        alert('网络繁忙');
                      })
                      .finally(() => {
                        setIsisLoading(false);
                      });
                  }}>
              <fieldset disabled={false}>
                <Row className="">
                  <Form.Group as={Col} className="mt-3">
                    <Form.Label className="fw-bold">姓名</Form.Label>
                    <Form.Control required name="name" type="text" placeholder=""/>
                  </Form.Group>
                  <Form.Group as={Col} className="mt-3">
                    <Form.Label className="fw-bold">身份证号</Form.Label>
                    <Form.Control required name="idcard" type="text" placeholder=""/>
                  </Form.Group>
                </Row>
                <Row className="">
                  <Form.Group as={Col} className="mt-3">
                    <Form.Label className="fw-bold">单位名称</Form.Label>
                    <Form.Control required name="company_name" type="text" placeholder=""/>
                  </Form.Group>
                  <Form.Group as={Col} className="mt-3">
                    <Form.Label className="fw-bold">职务</Form.Label>
                    <Form.Control required name="job" type="text" placeholder=""/>
                  </Form.Group>
                </Row>
                <Row className="">
                  <Form.Group as={Col} className="mt-3">
                    <Form.Label className="fw-bold">电话</Form.Label>
                    <Form.Control required name="mobile" type="tel" placeholder=""/>
                  </Form.Group>
                  <Form.Group as={Col} className="mt-3">
                    <Form.Label className="fw-bold">邮箱</Form.Label>
                    <Form.Control required name="email" type="email" placeholder=""/>
                  </Form.Group>
                </Row>
                <Row className="">
                  <Form.Group as={Col} className="mt-3">
                    <Form.Label className="fw-bold">证书邮寄地址</Form.Label>
                    <Form.Control required name="address" type="text" placeholder=""/>
                  </Form.Group>
                </Row>
                <Row className="">
                  <Form.Group as={Col} className="mt-3">
                    <Form.Label className="fw-bold">所属行业（如：发电、钢铁、建筑等）</Form.Label>
                    <Form.Control required name="industry" type="text" placeholder=""/>
                  </Form.Group>
                </Row>
                <Row className="">
                  <Form.Group as={Col} className="mt-3">
                    <Form.Label className="fw-bold">单位类型：</Form.Label>
                    {
                      [
                        '碳交易试点地区控排单位',
                        '碳交易非试点地区重点排放单位',
                        '各认证评价机构、第三方审核、服务机构',
                        '碳市场投资机构',
                        '碳排放管控人员',
                        '产业企业、研究机构、行业协会从业人员',
                        '出口企业负责技术性贸易措施研究人员',
                        '企业负责绿色低碳转型升级的中高层管理人员和技术人员',
                        '绿色低碳发展出口业务的企业',
                        '其他（请注明）',
                      ].map((v) => (
                          <Form.Check key={v}
                                      name={`company_type__${Math.random().toString().replace('.', '_')}`}
                                      value={v}
                                      type="checkbox"
                                      label={v}
                          />
                        )
                      )
                    }
                  </Form.Group>
                </Row>
                <Row className="">
                  <Form.Group as={Col} className="mt-3">
                    <Form.Label className="fw-bold">非以上单位类型（请注明）</Form.Label>
                    <Form.Control name="custom_company_type" type="text" placeholder=""/>
                  </Form.Group>
                </Row>
              </fieldset>

              <div className="mt-5 text-center">
                <Button variant="info"
                        className="d-inline-block mx-2 px-5 py-2 border-0 text-white"
                        size="sm"
                        type="submit"
                        disabled={isLoading}
                >{isLoading ? <Spinner size="sm" animation="border"/> : "提交"}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};
