import {Button, Spinner, Col, Container, Form, Modal, Row} from "react-bootstrap";

import React, {useEffect, useState} from "react";
import axios from "axios";
import Cookies from 'js-cookie';
import LeftNav from "../components/LeftNav";

axios.interceptors.request.use((config) => {
  if (config.headers == null) {
    config.headers = {};
  }

  // 必需，这样可以显示后端打印输出
  config.headers["X-Requested-With"] = "XMLHttpRequest";

  const _cookieId: any = Cookies.get('cea_id');

  if (_cookieId) {
    config.headers["X-Register-Ref"] = _cookieId;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

export default function () {
  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsisLoading] = useState(false);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
  }, [isMounted]);

  return(
    <>
      <div className="d-flex align-items-center min-vh-100"
           style={{
             backgroundImage: "url(https://fs-1300425912.cos.ap-shanghai.myqcloud.com/localupload/20221018/bg.png)",
             backgroundRepeat: "no-repeat",
             backgroundPosition: "center center",
             backgroundSize: "cover",
           }}>
        <Container className="py-5">
          <h4 className="text-center text-white">低碳产品供应商申报</h4>
          <div className={`mt-4 mx-auto w-75`} style={{
            transitionProperty: "width",
            transitionDuration: "0.7s",
          }}>
            <div className="p-5" style={{backgroundColor: "rgba(255,255,255,0.5)"}}>
              <Row>
                <Col className={"col-3"}>
                  <LeftNav/>
                </Col>
                <Col className={"col-9"}>
                  <div className="bg-white px-4 pb-5 pt-2 animate__animated animate__fadeIn">
                    <p className="my-0 text-end">
                      <a href="https://www.lowcarboncity.com.cn/" target="_blank" className="link-dark small d-inline-block ms-3" style={{textUnderlineOffset: "2px"}}>
                        低碳城首页 <i className="bi bi-box-arrow-up-right"></i>
                      </a>
                      <a href="https://www.lowcarboncity.com.cn/Attestation/user/homeIndex.html" target="_blank" className="link-dark small d-inline-block ms-3" style={{textUnderlineOffset: "2px"}}>
                        中国碳标签评价服务信息平台 <i className="bi bi-box-arrow-up-right"></i>
                      </a>
                    </p>
                    <h6 className="fw-normal text-info">温馨提示</h6>
                    <div className="my-3">
                      <p>低碳产品供应商评价程序：</p>
                      <p>1. 低碳产品供应商评价采取企业自愿申请及行业协会商会、有关单位推荐的办法。推荐单位应为碳标签授权评价机构或省级（及以上）协会商会。</p>
                      <p>2. 申报单位按照低碳产品供应商评价网上申报的流程操作，填报相关数据，扫描上传相关证书，打印《低碳产品供应商评价申报表》。</p>
                      <p>3. 申报单位、推荐单位将各方盖章后的低碳产品供应商评价申报表及相关材料报送协会低碳委。</p>
                      <p>4. 申报单位提出申请时，应提供下列材料：</p>
                      <p>（1）低碳产品供应商评价申报表（含真实性承诺书）；</p>
                      <p>（2）协会会员证明材料；</p>
                      <p>（3）企业法人营业执照和前一年度财务报表复印件；</p>
                      <p>（4）低碳产品供应商评价所需情况报告（与附件2《低碳产品供应商评价指标体系》规定相关的支持条件、有关材料及情况说明等）。</p>
                      <p>5. 低碳委组织专家对申请资料进行审核，依照本办法第十、十一条的规定开展评价，形成审定评价结果后需在协会、协会低碳委和低碳城官方网站进行公示，公示期为5个工作日。</p>
                      <p>6. 对于公示期满且没有异议的，由协会定期对低碳产品供应商名单进行公告，并颁发低碳产品供应商证书。</p>
                    </div>
                    <p className="text-muted small">* 推荐使用Chrome、火狐浏览器、360浏览器、搜狗浏览器、QQ浏览器，并关闭兼容模式。</p>
                    <hr className="mt-5"/>
                    <Row>
                      <Col className="text-start">
                        <Button variant="info"
                                size="sm"
                                className="px-5 py-2 border-0 text-white"
                                onClick={() => {window.location.href = '/guide?step=1'}}
                        >马上申请</Button>
                      </Col>
                      <Col className="text-end">
                        <Button className="px-5 py-2"
                                size="sm"
                                variant="outline-dark"
                                onClick={() => {
                                  // @ts-ignore
                                  window.open("https://work.weixin.qq.com/kfid/kfce6325b6574e7c42d");
                                }}
                        >联系客服</Button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
